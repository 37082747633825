/* Change Viewport specific Fontsize depending on wrapper layer context */

/* Defaults:
 * h1_initial   = 27/42
 * h1_post_768  = 36/48
 * h1_post_1024 = 42/60
 * h1_post_1440 = 54/78
 * h1_post_1920 = 66/96

 * h2_initial   = 21/30
 * h2_post_768  = 24/36
 * h2_post_1024 = 30/42
 * h2_post_1440 = 36/54
 * h2_post_1920 = 42/66
 */

/* from 0 to 767px the layer is always as wide as the viewport */
/* therefore no overrides */

/* from 768px to 860px the layer has a max width of 740px */
/* the layer grows bigger than 768px only after the viewport reaches a width of 888px */
@media all and (min-width: 768px) {
	.nm-layer .audi-headline-order-1,
	.modal-layer .audi-headline-order-1,
	.nm-layer .nm-3col .audi-headline-order-1,
	.nm-layer .nm-2col .audi-headline-order-1,
	.nm-layer .nm-1col .audi-headline-order-1,
	.modal-layer .nm-3col .audi-headline-order-1,
	.modal-layer .nm-2col .audi-headline-order-1,
	.modal-layer .nm-1col .audi-headline-order-1 {
		font-size: 2.7rem;
		line-height: 4.2rem;
	}

	.nm-layer .audi-headline-order-2,
	.modal-layer .audi-headline-order-2,
	.nm-layer .nm-3col .audi-headline-order-2,
	.nm-layer .nm-2col .audi-headline-order-2,
	.nm-layer .nm-1col .audi-headline-order-2,
	.modal-layer .nm-3col .audi-headline-order-2,
	.modal-layer .nm-2col .audi-headline-order-2,
	.modal-layer .nm-1col .audi-headline-order-2 {
		font-size: 2.1rem;
		line-height: 3rem;
	}
}

/* the layer grows bigger than 768px only after the viewport reaches a width of 888px */
/* it also never grows past 970px */
@media all and (min-width: 888px) {
	.nm-layer .audi-headline-order-1,
	.modal-layer .audi-headline-order-1,
	.nm-layer .nm-3col .audi-headline-order-1,
	.nm-layer .nm-2col .audi-headline-order-1,
	.nm-layer .nm-1col .audi-headline-order-1,
	.modal-layer .nm-3col .audi-headline-order-1,
	.modal-layer .nm-2col .audi-headline-order-1,
	.modal-layer .nm-1col .audi-headline-order-1 {
		font-size: 3.6rem;
		line-height: 4.8rem;
	}

	.nm-layer .audi-headline-order-2,
	.modal-layer .audi-headline-order-2,
	.nm-layer .nm-3col .audi-headline-order-2,
	.nm-layer .nm-2col .audi-headline-order-2,
	.nm-layer .nm-1col .audi-headline-order-2,
	.modal-layer .nm-3col .audi-headline-order-2,
	.modal-layer .nm-2col .audi-headline-order-2,
	.modal-layer .nm-1col .audi-headline-order-2 {
		font-size: 2.4rem;
		line-height: 3.6rem;
	}
}

/* past 1100px both 2col and 1col get smaller and are aligned aside */
@media all and (min-width: 1100px) {
	.nm-layer .nm-2col .audi-headline-order-1,
	.nm-layer .nm-1col .audi-headline-order-1,
	.modal-layer .nm-2col .audi-headline-order-1,
	.modal-layer .nm-1col .audi-headline-order-1 {
		font-size: 2.7rem;
		line-height: 4.2rem;
	}

	.nm-layer .nm-2col .audi-headline-order-2,
	.nm-layer .nm-1col .audi-headline-order-2,
	.modal-layer .nm-2col .audi-headline-order-2,
	.modal-layer .nm-1col .audi-headline-order-2 {
		font-size: 2.1rem;
		line-height: 3rem;
	}
}
