body {
	scroll-behavior: smooth;
}

.audi-footnotes {
	background-color: #4c4c4c;
	display: none;
	min-width: 0;
	padding-bottom: var(--space-xxxl);
	position: relative;
}

.audi-footnotes-wrapper {
	display: block;
}

.nm-footer > .audi-footnotes-wrapper {
	margin-top: var(--space-xl);
	padding-bottom: 0;
}

.audi-footnotes-list {
	box-sizing: border-box;
	color: #6d7579;
	margin: 0 auto;
	max-width: 1177.6px;
	min-width: 0;
	width: 92%;
}

[page-type=widescreen] .audi-footnotes-list {
	max-width: 1920px;
}

.audi-footnote {
	margin-bottom: var(--space-m);
	margin-left: 15px;
	position: relative;
}

.audi-footnote,
[data-theme="dark"] .audi-footnote.audi-copy-s {
	color: var(--color-grey-40);
}

.audi-footnote:last-child {
	margin-bottom: 0;
}

.audi-footnote:focus {
	outline: none;
}

.audi-footnote > sup {
	left: -13px;
	position: absolute;
	top: 0;
}

.audi-footnotes-list .audi-footnote a {
	color: var(--color-grey-30);
}

.audi-footnotes-list .audi-footnote a:hover {
	color: var(--color-white);
}

.audi-footnotes-list .nm-no-rate .nm-footnote-finance {
	display: none;
}

.modal-layer .audi-footnotes,
.nm-layer .audi-footnotes {
	padding-top: 20px;
}

.sc-fn-sup,
.sc-fn-index {
	cursor: pointer;
}

.modal-layer .audi-footnotes {
	background-color: transparent;
}

.modal-layer .audi-footnotes-list {
	border-top: 1px solid var(--color-grey-30);
	padding-top: 18px;
}

.modal-layer .audi-footnote {
	color: var(--color-black);
}

.audi-footnote-reference-disabled {
	display: none;
}

.audi-j-footnote-reference {
	color: inherit;
	padding: 0 !important;
	text-decoration: none;
}

.audi-j-footnote-reference::before {
	display: none !important;
}

.audi-j-footnote-reference:hover {
	color: inherit !important;
}

.nm-text-block a.audi-j-footnote-reference {
	text-decoration: none !important;
}

.audi-headline-order-1 .audi-footnote-anchor__text,
.audi-headline-order-2 .audi-footnote-anchor__text,
.audi-headline-order-3 .audi-footnote-anchor__text {
	font-size: .625em;
	line-height: inherit;
	top: -.5em;
}

.audi-footnote-reference__fixed-text .audi-footnote-anchor__text {
	font-size: inherit;
	text-decoration: inherit;
	top: auto;
}
