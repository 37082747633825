/*
* Modal Layer CSS
*/

.modal-layer {
	align-items: flex-start;
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNkYGDYz0AEYCJG0ahC6ikEAECXANNoGlngAAAAAElFTkSuQmCC");
	display: flex;
	height: 100%;
	left: 0;
	overflow: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 200;
}

.modal-layer.modal-layer--centered {
	align-items: center;
}

.modal-layer .modal-layer__shader-click-area {
	display: flex;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 101;
}

.modal-layer .modal-layer__content {
	background: #fff;
	height: auto;
	margin: 0 auto;
	max-width: 740px;
	position: relative;
	top: 0;
	width: auto;
	z-index: 102;
}

.modal-layer[data-layer-active="false"],
.modal-layer[data-layer-active="false"] .modal-layer__shader-click-area,
.modal-layer[data-layer-active="false"] .modal-layer__content {
	opacity: 0;
	transition: visibility 0s .25s, opacity .25s linear;
	visibility: hidden;
}

.modal-layer[data-layer-active="true"],
.modal-layer[data-layer-active="true"] .modal-layer__shader-click-area,
.modal-layer[data-layer-active="true"] .modal-layer__content {
	opacity: 1;
	transition: opacity .25s linear;
	visibility: visible;
}

.modal-layer.modal-layer--no-transition[data-layer-active="true"],
.modal-layer.modal-layer--no-transition[data-layer-active="false"] {
	transition: visibility 0s, opacity 0s linear;
}

.modal-layer.modal-layer-hidden-background {
	background: none;
}

/* prevent scroll on body, if layer is open */
body.modal-layer--open {
	overflow: hidden;
}

.modal-layer .modal-layer__content.modal-layer__content--hide {
	opacity: 0;
}

.modal-layer .modal-layer__content.modal-layer__content--show {
	opacity: 1;
}

.modal-layer .modal-layer__header {
	background: #e5e5e5;
	padding: var(--space-xxl) 4% var(--space-xl) 4%;
	position: relative;
}

.modal-layer .modal-layer__inner-content {
	margin: var(--space-xxl) 4% var(--space-xxxl) 4%;
}

.modal-layer .nm-layer-title {
	display: none;
}

.modal-layer .modal-layer--close {
	cursor: pointer;
	height: 32px;
	position: absolute;
	right: var(--space-m);
	top: var(--space-m);
	width: 32px;
}

.modal-layer .modal-layer--close-sticky-mobile {
	animation: fadein 2s;
	background-color: #fffc;
	border: 1px solid #fff;
	position: fixed;
	z-index: 3;
}

@keyframes fadein {
	from {
		background-color: #fff0;
		border: 1px solid #fff0;
	}

	to {
		background-color: #fffc;
		border: 1px solid #fff;
	}
}

.modal-layer .modal-layer__fullscreen-content-wrapper .modal-layer__inner {
	width: 100%;
}

@media screen and (min-width: 661px) {
	.modal-layer .modal-layer__header {
		padding: var(--space-xxl) 8% var(--space-xl) 8%;
	}

	.modal-layer .modal-layer__inner-content {
		margin: var(--space-xxl) 8% var(--space-xxxl) 8%;
	}
}

@media screen and (min-width: 739px) {
	.modal-layer .modal-layer--close-sticky-mobile {
		background-color: transparent;
		position: absolute;
		z-index: inherit;
	}
}

@media screen and (min-width: 860px) {
	.modal-layer .modal-layer__content {
		max-width: 970px;
		width: 86.53%;
	}
}
