/* Change Viewport specific Fontsize depending on wrapper context */

/* Defaults:
 * h1_initial   = 27/42
 * h1_post_768  = 36/48
 * h1_post_1024 = 42/60
 * h1_post_1440 = 54/78
 * h1_post_1920 = 66/96

 * h2_initial   = 21/30
 * h2_post_768  = 24/36
 * h2_post_1024 = 30/42
 * h2_post_1440 = 36/54
 * h2_post_1920 = 42/66
 */

/* from 0 to 1099px the all columns containers behave the same */
/* therefore no overrides */

/* starting at 1100px both 2col(max765px) and 1col never grow past the initial default */
@media all and (min-width: 1100px) {
	.nm-2col .audi-headline-order-1,
	.nm-1col .audi-headline-order-1 {
		font-size: 2.7rem;
		line-height: 4.2rem;
	}

	.nm-2col .audi-headline-order-2,
	.nm-1col .audi-headline-order-2 {
		font-size: 2.1rem;
		line-height: 3rem;
	}
}

/* the 3col wrapper never grows over 1400 px outside of the "widescreen" context */
@media all and (min-width: 1440px) {
	.nm-3col .audi-headline-order-1 {
		font-size: 4.2rem;
		line-height: 6rem;
	}

	.nm-3col .audi-headline-order-2 {
		font-size: 3rem;
		line-height: 4.2rem;
	}

	[page-type=widescreen] .nm-3col .audi-headline-order-1 {
		font-size: 5.4rem;
		line-height: 7.8rem;
	}

	[page-type=widescreen] .nm-3col .audi-headline-order-2 {
		font-size: 3.6rem;
		line-height: 5.4rem;
	}

}

@media all and (min-width: 1920px) {
	[page-type=widescreen] .nm-3col .audi-headline-order-1 {
		font-size: 6.6rem;
		line-height: 9.6rem;
	}

	[page-type=widescreen] .nm-3col .audi-headline-order-2 {
		font-size: 4.2rem;
		line-height: 6.6rem;
	}
}
