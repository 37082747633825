.nm-layer__close-button {
	background-color: var(--color-white);
	box-sizing: border-box;
	cursor: pointer;
	line-height: 0;
	position: absolute;
	right: 0px;
	z-index: 5;
}

.nm-layer__close-button:hover {
	color: var(--color-grey-70);
}

.nm-layer__close-button-icon {
	height: var(--icon-size-large);
	width: var(--icon-size-large);
}
