.nm-layer .nm-2col .nm-module-wrap {
	/* modules within this container can float all left with a margin and won't break */
	margin: 0 -14.7% 0 0;
}

.nm-layer .nm-3col .nm-module-wrap {
	margin-left: 0;
	width: 100%;
}

.nm-layer-dialogue-content .nm-3col {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.nm-layer .nm-2col {
	margin-bottom: 0;
	width: 65%;
}

.nm-layer .nm-1col,
.nm-has-basket.nm-layer-opened .nm-1col {
	display: block;
	width: 26%;
}

.nm-layer .nm-layer-inner .nm-layer-title {
	display: none;
}

.nm-layer-shader {
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNkYGDYz0AEYCJG0ahC6ikEAECXANNoGlngAAAAAElFTkSuQmCC");
	height: 100%;
	left: 0;
	opacity: 0;
	position: fixed;
	top: 0;
	transition: opacity .25s;
	width: 100%;
	z-index: 100;
}

.nm-error-layer-layer-opened .nm-layer-shader,
.nm-config-layer-open .nm-layer-shader {
	z-index: 102;
}

.nm-layer-shader.nm-layer-shader-no-bg {
	background: transparent;
}

/* conflict/transfer/error layer */

.nm-conflict-layer-opened .nm-layer-shader,
.nm-conflict-layer-layer-opened .nm-layer-shader,
.nm-transfer-layer-layer-opened .nm-layer-shader,
.nm-error-layer-opened .nm-layer-shader {
	z-index: 102;
}

/* browserscrollbar */
.nm-layer-wrapper {
	height: 100%;
	overflow: auto;
	position: relative;
	width: 100%;
	z-index: 102;
}

/* layercontent - fades */
.nm-layer {
	margin: 0 auto;
	opacity: 0;
	position: relative;
	top: 0;
	transition: opacity .5s;
	width: 100%;
	z-index: 101;
}

.nm-layer-shader.nm-layer-shader-visible,
.nm-layer-visible.nm-layer {
	opacity: 1;
}

/* This Shader covers the content when the special is switched to dusk or night */

.nm-layer .nm-stage-special-shader {
	max-width: 1177.6px;
}

/* layer inner is the actual visible layer */

.nm-layer-inner {
	background: #fff;
	float: left;
	height: 100%;
	position: relative;
	width: 100%;
}

/* layer header */

.nm-layer-head {
	/* provides a whitespace even with no media content */
	margin: 0 0 50px;
	min-height: 6px;
	position: relative;
}

.nm-layer-head-empty {
	margin-bottom: 0;
	min-height: 0;
}

.nm-layer-head .nm-similar-notification {
	background-color: rgba(0, 0, 0, .6);
	box-sizing: border-box;
	color: #fff;
	display: block;
	left: 23%;
	min-height: 20px;
	padding: 15px;
	position: absolute;
	top: 25px;
	width: 54%;
	z-index: 1;
}

.nm-layer-head [data-video-state="playing"] .nm-similar-notification {
	display: none;
}

/* layer stage */

.nm-layer-image {
	height: 0;
	overflow: hidden;
	padding-bottom: 42%;
	position: relative;
	width: 100%;
}

.nm-layer-image.nm-layer-image--simple {
	height: auto;
	overflow: hidden;
	padding-bottom: 0;
	position: relative;
	width: 100%;
}
/* contains an image */

.nm-layer-image img {
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-layer-image.nm-layer-image--simple img {
	height: auto;
	position: static;
	width: 100%;
}

.nm-layer-image .nm-j-configurator-renderimage {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	display: block;
	height: 0;
	padding-bottom: 40.75%;
	width: 100%;
}

.nm-layer-image .nm-j-configurator-renderimage[data-renderimage-type="fullscreen"],
.nm-layer-image .nm-j-configurator-renderimage[data-renderimage-type="fullscreen.new"] {
	padding-bottom: 60%;
}

.nm-mediathek-image .nm-layer-image {
	padding-bottom: 56%;
}

.nm-mediathek-image .nm-layer-image.nm-layer-image--simple {
	padding-bottom: 0;
}

.nm-mediathek-image .nm-layer-image.nm-layer-image-square img {
	height: 100%;
	left: 22%;
	width: 56%;
}

.nm-layer-image.nm-layer-image-fullscreen {
	padding-bottom: 60%;
}

/* multilayer stage */

.nm-layer-multi-stage {
	overflow: hidden;
	padding-bottom: 31.3%;
	position: relative;
}

.nm-layer-multi-stage .nm-stage-browse {
	height: 1px;
	position: absolute;
	top: calc(50%);
	width: 100%;
	z-index: 2;
}

.nm-layer-multi-stage .nm-stage-browse::selection,
.nm-layer-multi-stage .nm-backarrow::selection,
.nm-layer-multi-stage .nm-nextarrow::selection,
.nm-layer-multi-stage .nm-image-replace::selection {
	background: none;
}

.nm-layer-multi-stage .nm-layer-image {
	left: 0;
	padding-bottom: 31.3%;
	position: absolute;
	top: 0;
	transition: all .5s;
}

.nm-layer-multi-stage.no-animation .nm-layer-image {
	transition: none;
}

.nm-layer-multi-stage .nm-layer-image.nm-active {
	z-index: 1;
}

.nm-layer-multi-stage.nm-animation-forward .nm-layer-image,
.nm-layer-multi-stage.nm-animation-backward .nm-layer-image {
	left: 0;
}

.nm-layer-multi-stage.nm-animation-forward.no-animation .nm-layer-image {
	left: 100%;
}

.nm-layer-multi-stage.nm-animation-forward .nm-layer-image.nm-active {
	left: -100%;
}

.nm-layer-multi-stage.nm-animation-backward .nm-layer-image.nm-active {
	left: 100%;
}

.nm-layer-multi-stage.nm-animation-backward.no-animation .nm-layer-image {
	left: -100%;
}

.nm-layer-multi-stage.nm-animation-forward.no-animation .nm-layer-image.nm-active,
.nm-layer-multi-stage.nm-animation-backward.no-animation .nm-layer-image.nm-active {
	left: 0;
}

.nm-layer-multi-stage .nm-layer-stage-image {
	background-size: cover;
	display: block;
	height: 0;
	padding-bottom: 31.3%;
}

/* without images */

.nm-layer-image.nm-layer-header-empty {
	background: #fff;
	margin-bottom: 0;
	padding-bottom: 0;
}

/* gallery gets a gradient */

.nm-gallery-image .nm-layer-image.nm-layer-header-empty {
	background: url("../assets/image/bg-head-gradient-layer.png") no-repeat left top;
}

.nm-layer-content {
	margin: 55px auto 0;
	padding-bottom: 80px;
	position: relative;
	width: 92%;
	z-index: 1;
}

.nm-layer-footnotes {
	margin: -80px auto 0;
	padding-bottom: 80px;
	position: relative;
	width: 92%;
	z-index: 1;
}

.nm-layer-head + .nm-layer-content {
	margin-top: 0;
}

.nm-layer-head.nm-layer-head-empty + .nm-layer-content {
	margin-top: 80px;
}

/*	layer popup - grid */

.nm-layer-wrapper-dialogue,
.nm-layer-wrapper-conflict,
.nm-layer-wrapper-transfer {
	text-align: center;
	/* centers the layer horizontally */
	z-index: 102;
}

.nm-layer-wrapper-dialogue::before,
.nm-layer-wrapper-conflict::before,
.nm-layer-wrapper-transfer::before {
	/* inserts a ghost element for enableing the layer to be centered vertically */
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.nm-config-layer-open .nm-layer-wrapper-dialogue {
	z-index: 103;
}

.nm-conflict-layer-opened .nm-layer-wrapper-dialogue,
.nm-conflict-layer-layer-opened .nm-layer-wrapper-dialogue {
	z-index: 103;
}

.nm-conflict-layer-layer-opened .nm-layer-wrapper-conflict {
	position: absolute;
	top: 0;
}

.nm-layer-dialogue,
.nm-layer-conflict,
.nm-layer-transfer {
	display: inline-block;
	max-width: 750px;
	text-align: left;
	vertical-align: middle;
	width: 750px;
}

.nm-layer-dialogue-content,
.nm-layer-conflict-content,
.nm-layer-transfer-content {
	padding: 75px 8% 50px;
}

.nm-layer-dialogue-content .nm-2col {
	width: 65%;
}

.nm-layer-dialogue .nm-3col .nm-50col,
.nm-layer-dialogue .nm-3col .nm-50col:first-child {
	border: none;
	padding: 0;
	width: 100%;
}

.nm-layer-dialogue .nm-3col .nm-50col > div {
	box-sizing: border-box;
	max-width: 315px;
	padding-left: 30px;
	width: 37.3vw;
}

.nm-layer-dialogue .nm-3col .nm-50col:first-child > div {
	border-right: 1px solid #d0d3d4;
	padding-left: 0;
	padding-right: 30px;
}

/*	layer full size - grid */

.nm-layer-full .nm-layer {
	height: 100%;
	max-width: 100%;
	min-width: 100%;
	width: 100%;
}

/*	layer forms - grid */

.nm-form-shader {
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWP89+//fgYiABMxikYVUk8hAADpA869ASoHAAAAAElFTkSuQmCC");
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.nm-layer.nm-layer-form {
	/* 980/ 1080 = 0,907407 || maximale Breite */
	max-width: 980px;
	text-align: left;
	/* overwrites the text-centering in the parent element */
	vertical-align: middle;
	width: 90.74%;
}

.nm-layer.nm-layer-form .nm-layer-content {
	/* margin right/left = 50px NEMO-1540 */
	max-width: 930px;
	width: 89.897%;
}

/*	layer error - grid */

.nm-error-layer {
	z-index: 104;
}

.nm-error-layer .nm-layer-btn-ok {
	float: right;
	text-align: center;
}

.nm-error-layer .nm-layer-btn-ok a {
	box-sizing: border-box;
	justify-content: center;
	min-width: 80px;
}

.nm-notification-layer .nm-layer-btn-ok a {
	margin-bottom: 25px;
	margin-top: 25px;
}

.nm-error-layer .nm-layer-dialogue-content p {
	margin-bottom: 48px;
}

/* TO DO: Shift these somewhere else */

.nm-layer-dialogue .nm-options-list {
	margin-bottom: 35px;
}

.nm-layer-dialogue .nm-c2 {
	text-align: right;
}

.nm-layer-dialogue .nm-info {
	top: 4px;
}

.nm-layer .nm-j-quantity-selected.nm-price-total {
	margin: -2px 0 12px;
}

.nm-module.nm-md-configurationstart-implicit-layer {
	margin-bottom: 0;
}

.nm-md-configurationstart-implicit-layer strong {
	margin-bottom: 13px;
}

/* tabs close button - closes tab groups in layers */
.nm-layer-wrapper .nm-layer-toolbar .nm-innerlayer-close {
	align-items: center;
	background-color: #000;
	/* by using a box shadow instead of a border the hover effect applies only to the element, not to the border */
	box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, .15);
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	/* flex for aligning the x center/center */
	display: inline-flex;
	height: 30px;
	justify-content: center;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 30px;
	/* button is 48px minus 10px border */
	z-index: 3;
}

.nm-layer-wrapper .nm-layer-toolbar .nm-innerlayer-close:hover {
	background-color: #434c53;
}

body.nm-ensBanner-isVisible.nm-ensBanner-isVisible--showLegalLayers .ensPrivacyManager {
	display: none !important;
}

@media all and (max-width: 1099px) {
	.nm-state-is-responsive .nm-layer .nm-2col .nm-module-wrap {
		margin: 0;
	}
}

@media all and (max-width: 1023px) {
	.nm-state-is-responsive .nm-layer,
	.nm-state-is-responsive .nm-layer.nm-layer-form {
		background-color: #fff;
		min-height: 48px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-layer.nm-layer-form .nm-layer-content,
	.nm-state-is-responsive .nm-layer.nm-layer-form .nm-layer-dialogue-content,
	.nm-state-is-responsive .nm-layer.nm-layer-dialogue,
	.nm-state-is-responsive .nm-layer.nm-layer-transfer-default {
		margin: 0 4%;
		min-width: 0;
		width: 92%;
	}

	body.nm-layer-opened:not(.nm-carpark-shader-visible):not(.nm-ensBanner-isVisible) > *:not(.modal-layer):not(.nm-layer-wrapper):not(.nm-layer-shader) {
		display: none !important;
		height: 0;
		overflow: hidden;
		visibility: hidden !important;
	}

	body.nm-layer-opened:not(.nm-carpark-shader-visible).nm-ensBanner-isVisible.nm-ensBanner-isVisible--showLegalLayers > *:not(.modal-layer):not(.nm-layer-wrapper):not(.nm-layer-shader) {
		display: none !important;
		height: 0;
		overflow: hidden;
		visibility: hidden !important;
	}

	.nm-state-is-responsive .nm-default-layer .nm-layer-toolbar,
	.nm-state-is-responsive .nm-layer-form .nm-layer-toolbar {
		background: rgba(255, 255, 255, .92);
		border-bottom: 1px solid var(--color-grey-05);
		content: "";
		display: block;
		height: 48px;
		position: fixed;
		top: 0;
		transform: translateZ(0); /* TODO: Review */
		width: 100%;
		z-index: 16;
	}

	.nm-state-is-responsive .nm-default-layer .nm-layer-toolbar.nm-suppress-space-toolbar {
		border-bottom: none;
	}

	.nm-state-is-responsive .nm-default-layer .nm-layer-toolbar + .nm-layer-inner,
	.nm-state-is-responsive .nm-layer-form .nm-layer-toolbar + .nm-layer-inner {
		margin-top: 48px; /* Toolbar Height */
	}

	.nm-state-is-responsive .nm-default-layer .nm-layer-toolbar.nm-suppress-space-toolbar + .nm-layer-inner {
		margin-top: 0px;
	}

	.nm-state-is-responsive .nm-default-layer .nm-layer-toolbar .nm-button-close,
	.nm-state-is-responsive .nm-layer-form .nm-layer-toolbar .nm-button-close {
		right: 15px;
		top: 0;
	}

	.nm-state-is-responsive .nm-default-layer .nm-layer-toolbar .nm-button-back,
	.nm-state-is-responsive .nm-layer-form .nm-layer-toolbar .nm-button-back {
		background: transparent;
		box-shadow: none;
		color: #000;
		margin: 0 47px 0 10px;
	}

	.nm-state-is-responsive .nm-default-layer .nm-layer-toolbar .nm-button-back::before,
	.nm-state-is-responsive .nm-layer-form .nm-layer-toolbar .nm-button-back::before {
		background: url("../assets/img/sprite-main.png") no-repeat 10px -449px transparent;
		content: "";
		display: block;
		height: 15px;
		left: 0;
		position: absolute;
		width: 20px;
	}
}

@media all and (max-width: 749px) {
	.nm-notification-layer .nm-el-hdl {
		margin-top: 20px;
	}

	.nm-layer-dialogue .nm-3col .nm-50col > div {
		max-width: 100%;
		padding: 0;
		width: 100%;
	}

	.nm-layer-dialogue .nm-3col .nm-50col:first-child > div {
		border: none;
	}

	.nm-layer-head.nm-layer-head-empty + .nm-layer-content {
		margin-top: 48px;
	}

	.nm-state-is-responsive .nm-layer-dialogue-content {
		padding: 75px 4% 50px;
	}

	.nm-state-is-responsive .nm-layer-wrapper-dialogue .nm-layer-dialogue-content {
		padding: 75px 4%;
	}

	.nm-layer-head .nm-similar-notification {
		left: 0;
		top: 0;
		width: 100%;
	}
}

@media all and (max-width: 599px) {
	.nm-state-is-responsive .nm-layer-multi-stage .nm-stage-browse {
		z-index: 0;
	}
}

@media all and (max-width: 479px) {
	.nm-state-is-responsive .nm-layer.nm-layer-dialogue,
	.nm-state-is-responsive .nm-layer.nm-layer-transfer-default {
		margin: 0;
		max-width: 740px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-layer-wrapper-dialogue .nm-layer-toolbar {
		background: rgba(255, 255, 255, .92);
		content: "";
		display: block;
		height: 48px;
		position: fixed;
		position: sticky;
		top: 0;
		transform: translateZ(0); /* TODO: Review */
		width: 100%;
		z-index: 16;
	}

	.nm-state-is-responsive .nm-layer-wrapper-dialogue .nm-layer-toolbar .nm-button-close {
		right: 15px;
		top: 0;
	}

	.nm-state-is-responsive .nm-layer-wrapper-dialogue .nm-layer-toolbar .nm-button-back {
		background: transparent;
		color: #000;
		margin: 0 47px 0 10px;
	}

	.nm-state-is-responsive .nm-layer-wrapper-dialogue .nm-layer-toolbar .nm-button-back::before {
		background: url("../assets/img/sprite-main.png") no-repeat 10px -449px transparent;
		content: "";
		display: block;
		height: 15px;
		left: 0;
		position: absolute;
		width: 20px;
	}

	.nm-state-is-responsive .nm-layer-wrapper-dialogue .nm-layer {
		max-width: none;
		min-height: 100%;
		width: 100%;
	}

	.nm-state-is-responsive .nm-layer-wrapper-dialogue .nm-layer-dialogue-content {
		padding: 20px 4% 48px;
	}

	.nm-state-is-responsive .nm-configurationstart-loaded-layer {
		margin-top: 15px;
	}

	.nm-state-is-responsive .nm-configurationstart-layer-close {
		bottom: auto;
		margin-top: 5px;
		max-width: none;
		position: relative;
		right: auto;
	}
}

@media all and (max-width: 429px) {
	.nm-layer-head {
		display: block;
	}

	.nm-layer-head .nm-similar-notification {
		background: #fff;
		color: #000;
		position: relative;
	}

	.nm-layer-head .nm-layer-image {
		height: auto;
		padding-bottom: 0;
	}

	.nm-layer-head .nm-layer-image img {
		position: relative;
	}
}

@media all and (min-width: 1024px) {
	.nm-layer {
		margin: 0 auto;
		max-height: 100vh;
		max-width: 1440px;
		min-height: 350px;
		top: 0;
		width: 76%;
	}

	.nm-layer-dialogue,
	.nm-layer-conflict,
	.nm-layer-transfer {
		max-width: 750px;
		min-width: 750px;
	}

	.nm-layer-opened:not(.nm-carpark-shader-visible) {
		overflow: hidden;
	}

	.nm-layer-wrapper {
		left: 0;
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
		overflow-y: scroll;
		position: fixed;
		top: 0;
		z-index: 102;
	}

	.nm-conflict-layer-layer-opened .nm-layer-wrapper-conflict {
		position: fixed;
	}
}
