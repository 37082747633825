/* layer fallback */
.nm-layer-fallback .nm-layer {
	display: block;
	max-width: 1920px;
	opacity: 1;
	position: static;
	width: 100%;
}

.nm-layer-fallback .nm-layer-wrapper {
	height: auto;
	left: auto;
	overflow: auto;
	position: relative;
	top: auto;
	z-index: 1;
}

.nm-layer-fallback .nm-wrapper {
	overflow: visible;
}

.nm-layer-fallback .nm-layer-content {
	max-width: 1920px;
	padding-bottom: 0;
	width: auto;
}

.nm-layer-fallback .nm-layer-content > :not(.nm-2col),
.nm-layer-fallback .nm-layer-content > :not(.nm-1col) {
	margin-left: var(--space-column);
	margin-right: var(--space-column);
	max-width: calc(100% - (2 * var(--space-column)));
}

.nm-layer-fallback .nm-2col {
	margin-left: var(--space-column);
	width: 61%;
}

.nm-layer-fallback .nm-1col {
	margin-right: var(--space-column);
	width: 22%;
}

.nm-layer-fallback .nm-1col > *,
.nm-layer-fallback .nm-2col > *,
.nm-layer-fallback .nm-3col > * {
	margin-left: 0 !important;
	margin-right: 0 !important;
	max-width: 100%;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

@media (min-width: 768px) {
	.nm-layer-fallback .nm-layer-content {
		max-width: 1177.6px;
	}

	[page-type=widescreen].nm-layer-fallback .nm-layer-content {
		max-width: 1440px;
	}
}

@media (max-width: 1099px) {
	.nm-state-is-responsive .nm-layer-fallback .nm-1col,
	.nm-state-is-responsive .nm-layer-fallback .nm-2col {
		width: 92%;
	}
}

@media all and (min-width: 1024px) {
	.nm-layer-fallback .nm-layer-content > :not(.nm-2col),
	.nm-layer-fallback .nm-layer-content > :not(.nm-1col) {
		margin-left: var(--space-column);
		margin-right: var(--space-column);
		max-width: calc(100% - (2 * var(--space-column)));
	}
}

@media all and (min-width: 1400px) {
	.nm-layer-fallback .nm-layer-content {
		margin-left: auto;
		margin-right: auto;
		max-width: 1400px;
	}
}
